









































































































































import { computed, defineComponent, ref } from '@nuxtjs/composition-api'
import CLink from '~/components/shared/configurable/link/CLink.vue'
import CIcon from '~/components/shared/configurable/CIcon.vue'
import CAvatar from '~/components/shared/configurable/image/avatar/CAvatar.vue'
import { ciUserCircle as ciUserCircleRegular } from '~/icons/source/regular/user-circle'
import { ciUserCircle as ciUserCircleSolid } from '~/icons/source/solid/user-circle'
import { ciFolderOpen } from '~/icons/source/regular/folder-open'
import { ciParking } from '~/icons/source/regular/parking'
import { useNamespacedStore } from '~/compositions/store'
import { USER_NS, UserState } from '~/store/modules/shared/user/state'
import { formatNumber } from '~/utils/number'
import { useDep } from '~/compositions/dependency-container'
import { LegacyUrlService } from '~/services/legacy/url/LegacyUrlService'
import { UserType } from '~/models/user/types'
import { capitalizeString } from '~/utils/string'
import HeaderUserIcon from '~/components/car/header/HeaderUserIcon.vue'
import { defineVue3AsyncComponent } from '~/utils/nuxt3-migration'

export default defineComponent({
  components: {
    CLink,
    CIcon,
    CAvatar,
    HeaderUserIcon,
    CreditsAnalysisBadges: defineVue3AsyncComponent(() =>
      import('~/components/car/header/CreditsAnalysisBadges.vue')
    ),
    AdminSubmenu: defineVue3AsyncComponent(() =>
      import('~/components/shared/header/AdminSubmenu.vue')
    ),
    UserSubmenu: defineVue3AsyncComponent(() =>
      import('~/components/car/header/UserSubmenu.vue')
    )
  },
  setup() {
    const legacyUrlService = useDep(LegacyUrlService)
    const { state: userState, getters: userGetters } = useNamespacedStore<
      UserState
    >(USER_NS)

    const userLogoutLink = legacyUrlService.getUserLogoutUrl()
    const username = computed(() => userState.name)
    const avatar = computed(() => userState.avatar)
    const isAdmin = computed(() => userGetters('isAdmin'))
    const isAnon = computed(() => userGetters('isSingleOrAnon'))
    const totalCredits = computed(() => userGetters('totalCredits'))
    const totalCreditsFormatted = computed(() =>
      formatNumber(totalCredits.value)
    )
    const siteDomain = computed(() => {
      return capitalizeString(process.env.DOMAIN || 'car.gr')
    })
    const showCredits = computed(
      () => !isAdmin.value && userState.type !== UserType.AUDIT_PROVIDER
    )

    const shown = ref(false)
    const enter = () => {
      shown.value = true
    }
    const leave = () => {
      shown.value = false
    }
    return {
      ciUserCircleRegular,
      ciUserCircleSolid,
      ciFolderOpen,
      ciParking,
      username,
      avatar,
      totalCredits,
      totalCreditsFormatted,
      isAdmin,
      isAnon,
      userLogoutLink,
      shown,
      siteDomain,
      showCredits,
      enter,
      leave
    }
  }
})
